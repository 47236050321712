<template>
  <div id="addBlacklist" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5"
        >LISTA NEGRA DE EMPLEADOS</v-row
      >
      <v-container fluid class="addBlackList">
        <v-row no-gutters justify="end">
          <v-col cols="12" lg="5" md="5" sm="5" class="marginCol blackList">
            <v-row>
              <div class="form-group">
                <label for="enterprise">Empresa Pagadora</label>
                <select name="enterprise" id="enterprise" v-model="enterprise">
                  <option selected disabled value="0">
                    Selecciona Empresa
                  </option>
                  <option
                    v-for="enterprise in enterprises"
                    :key="enterprise.id"
                    v-bind:value="enterprise.id"
                  >
                    {{ enterprise.razonSocial }}
                  </option>
                </select>
              </div>
            </v-row>
            <v-row>
              <div class="form-group">
                <label for="employeeNumber">Número de Empleado</label>
                <input
                  type="text"
                  name="employeeNumber"
                  id="employeeNumber"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="employeeNumber"
                  required
                  maxlength="5"
                  @keypress="isNumber($event)"
                />
              </div>
            </v-row>
            <v-row>
              <div class="form-group">
                <label for="rfc">RFC</label>
                <input
                  type="text"
                  name="rfc"
                  id="rfc"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="rfc"
                  required
                  maxlength="13"
                  @keypress="validaRfc($event)"
                />
              </div>
            </v-row>
            <v-row>
              <label for="comments">Comentarios</label>
              <textarea
                id="comments"
                name="comments"
                placeholder="Ingresa un comentario..."
                style="margin-top: 0px"
                rows="4"
                cols="20"
                v-model="comment"
              ></textarea>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" align-self="center">
            <v-row justify="center">
              <button class="botonAmarillo" @click="validacion()">
                Guardar
              </button>
              <router-link to="/employeeBlacklists" id="backEmployee">
                <button class="botonAmarilloDerecho">Regresar</button>
              </router-link>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">LISTA NEGRA</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <ul>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            {{ respuesta }}
          </h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">{{ message }}</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      enterprises: [],
      enterprise: localStorage.empresaIdGlobal,
      employeeNumber: 0,
      comment: "",
      rfc: "",
      errors: [],
      error: false,
      respuesta: "",
      advice: false,
      confirmation: false,
      message: "",
    };
  },
  methods: {
    validaRfc() {
      var strCorrecta;
      strCorrecta = this.rfc;
      if (this.rfc.length == 12) {
        var valid = "^(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))";
      } else {
        var valid =
          "^(([A-Z]|[a-z]|s){1})(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))";
      }
      var validRfc = new RegExp(valid);
      var matchArray = strCorrecta.match(validRfc);
      if (matchArray == null) {
        this.errors.push("El RFC es incorrecto favor de verificarlo.");
        return false;
      } else {
        //console.log("Correcto");
        return true;
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    validacion() {
      this.errors = [];
      if (this.enterprise == "" || this.enterprise == 0) {
        this.errors.push("Se debe especificar la empresa.");
      }
      if (this.employeeNumber == "" || this.employeeNumber == 0) {
        this.errors.push("Se debe especificar el número de empleado.");
      }
      this.validaRfc();
      if (this.errors.length == 0) {
        this.message = "LISTA NEGRA";
        this.respuesta =
          "¿Esta seguro de agregar el empleado a la lista negra?";
        this.advice = true;
      } else {
        this.respuesta = "Por favor corrige el(los) siguiente(s) error(es):";
        this.error = true;
      }
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    guardar() {
      this.advice = false;
      this.show = true;
      axios
        .post(
          Server + "/empleados/lista-negra",
          {
            EmpresaId: this.enterprise,
            EmpleadoId: this.employeeNumber,
            RFC: this.rfc,
            Comentarios: this.comment,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.enterprise = 0;
          this.employeeNumber = 0;
          this.rfc = "";
          this.comment = "";
          this.show = false;
          this.message = "LISTA NEGRA";
          this.respuesta =
            "El empleado fue agregado a la lista negra con éxito";
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.enterprise = 0;
            this.employeeNumber = 0;
            this.rfc = "";
            this.comment = "";
            this.show = false;
            this.message = "LISTA NEGRA";
            this.respuesta =
              "Los datos ingresados son incorrectos o el empleado no ha sido dado de baja.";
            this.confirmation = true;
          }
        });
    },
    cancelar() {
      this.rfc = "";
      this.comment = "";
      this.employeeNumber = "";
      this.enterprise = 0;
      this.advice = false;
    },
  },
  created() {
    this.listar();
  },
};
</script>