<template>
  <div id="employees" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5"
        >LISTA NEGRA DE EMPLEADOS</v-row
      >
      <v-row no-gutters justify="end" class="mr-12 mb-4">
        <v-col
          cols="12"
          lg="6"
          md="5"
          sm="4"
          xs="12"
          class="selectEmpresa"
          align-self="end"
        >
          <v-row class="ml-12">
            <label for="enterprise" class="ml-5 mr-2">Empresa Pagadora</label>
            <select
              name="enterprise"
              id="enterprise"
              v-model="enterprise"
              @change="obtenerEmpresa()"
            >
              <option selected disabled value="0">Selecciona Empresa</option>
              <option
                v-for="enterprise in enterprises"
                :key="enterprise.id"
                v-bind:value="enterprise.id"
              >
                {{ enterprise.razonSocial }}
              </option>
            </select>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="5" xs="12">
          <v-card-title>
            <router-link to="/addBlacklist" id="nuevoempleado">
              <button class="breakSearch botonAmarillo">
                Alta de Empleado
              </button>
            </router-link>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="employees"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Empleados por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.id }}</td>
                  <td class="text-sm-center">
                    {{ item.nombre + " " + item.apellidoPaterno }}
                  </td>
                  <td class="text-sm-center">{{ item.rfc }}</td>
                  <td class="text-sm-center">{{ item.area.nombreArea }}</td>
                  <td class="text-sm-center">
                    {{
                      item.sucursal != null ? item.sucursal.nombreSucursal : ""
                    }}
                  </td>
                  <td class="justify-center layout px-0">
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="editItem(item)"
                      >fa-edit</v-icon
                    >
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="viewItem(item)"
                      >fa-eye</v-icon
                    >
                    <v-icon color="red" small @click="temporal(item)"
                      >fa-trash-alt</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="advice = false">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="deleteItem()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Empleado</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--DIALOG DE DATOS-->
    <v-dialog v-model="dialog" max-width="400px">
      <v-card>
        <v-card-title class="orange white--text"
          >Información de Empleado</v-card-title
        >
        <v-card-text id="info">
          <div class="form-group mt-5">
            <label>Numero de Empleado:</label>
            {{ employeeSelect.id }}
          </div>
          <div class="form-group">
            <label>Nombre:</label>
            {{ employeeSelect.nombre + employeeSelect.apellidoPaterno + employeeSelect.apellidoMaterno }}
          </div>
          <div class="form-group">
            <label>RFC:</label>
            {{ employeeSelect.rfc }}
          </div>
          <div class="form-group">
            <label>Area:</label>
            {{ employeeSelect.area }}
          </div>
          <div class="form-group">
            <label>Motivo:</label>
            {{ employeeSelect.comentarioListaNegra }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      headers: [
        {
          text: "N° Empleado",
          align: "center",
          value: "id",
        },
        {
          text: "Nombre",
          align: "center",
          value: "nombre",
        },
        {
          text: "RFC",
          align: "center",
          value: "rfc",
        },
        {
          text: "Area/Departamento",
          align: "center",
          value: "area.nombreArea",
        },
        {
          text: "Sucursal",
          align: "center",
          value: "sucursal.nombreSucursal",
        },
        {
          text: "Editar  /   Ver ",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      employees: [],
      employeeSelect:
        {
          id: 1,
          nombre: "Pedroddd",
          apellidoMaterno: "Salazar",
          apellidoPaterno: "Ortiz",
          area: {
            nombreArea:"X"
          },
          areaId:0,
          puestoId:0,
          puesto:{},
          sucursal: {
            nombreSucursal:""
          },
          sucursalId:0,
          comentarioListaNegra:"",
          rfc:"XXX"
        },
      enterprises: [],
      enterprise: localStorage.empresaIdGlobal,
      respuesta: "",
      advice: false,
      id: "",
      confirmation: false,
      edit: false,
      vacio: false,
      name: "",
    };
  },
  methods: {
    validaRfc() {
      var regex = new RegExp(
        "^[A-Z,Ñ,&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z,0-9]?[A-Z,0-9]?[0-9,A-Z]?$"
      ); //ER para RFC
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        this.errorRfc = false;
        return true;
      }
      evt.preventDefault();
      this.errorRfc = false;
      return false;
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    //recupera datos para enviar a la actualización de empleados
    editItem(item) {
      localStorage.empleadoListaNegra = item.id;
      localStorage.rfc = item.rfc;
      localStorage.comentarioListaNegra = item.comentarioListaNegra;
      this.$router.push("/updateEmployeeBlackList");
    },
    //recupera el nombre del empleado para alert
    temporal(item) {
      this.id = item.employeeNumber;
      this.name = item.name;
      this.respuesta =
        "¿ Seguro que desea eliminar el empleado " + item.name + " ?";
      this.advice = true;
    },
    deleteItem() {
      this.advice = false;
      this.respuesta = "";
      /* APi
      axios
        .delete(Server + "/empleados/" + this.id)
        .then(response => {
          console.log(response);
          this.id = "";
          this.respuesta = "El elemento fue eliminado con éxito";
          this.confirmation = true;
        })
        .catch(e => {
          console.log(e);
          this.id = "";
          this.respuesta = "El elemento no fue eliminado";
          this.confirmation = true;
        });*/
    },
    viewItem(item) {
      this.employeeSelect= item;
      this.employeeSelect.area = item.area.nombreArea;
      this.dialog = true;
    },
    obtenerEmpresa() {
      this.employees = [];
      this.show = true;
      axios
        .get(
          Server + "/empleados/por-empresa/" + this.enterprise + "?estatus=3",
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.employees = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listar();
    this.obtenerEmpresa();
  },
};
</script>